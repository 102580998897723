<template>
  <table class="table table-hover table-responsive">
    <thead>
      <tr>
        <th>Id</th>
        <th>Bot Name</th>
        <th>Pair</th>
        <th>Status</th>
        <th>Strategy</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <item v-for="_bot in bots" :key="_bot.id" :bot="_bot" />
    </tbody>
  </table>
</template>

<script>
import Item from "./Item.vue";

export default {
  name: "Macd Bots",
  components: {
    Item,
  },
  props: ["bots"],
};
</script>
