<template>
  <tr>
    <th scope="row">{{ bot.id }}</th>
    <td>{{ bot.bot_name }}</td>
    <td>{{ bot.pair }}</td>
    <td>{{ bot.status }}</td>
    <td>{{ bot.w_position }}</td>
    <td>
      <div class="btn-group">
        <router-link :to="`/macd_bots/${bot.id}`" class="btn btn-info"
          ><i class="bi bi-eye"></i
        ></router-link>
        <router-link to="#" class="btn btn-warning"
          ><i class="bi bi-pencil-square"></i
        ></router-link>
        <button type="button" class="btn btn-danger" @click="deleteBot(bot.id)">
          <i class="bi bi-trash"></i>
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import { useStore } from "vuex";
export default {
  name: "Macd Bot",
  props: ["bot"],
  setup() {
    const store = useStore();
    const deleteBot = (id) => {
      store.dispatch("macd_bots/deleteBot", { pk: id });
    };
    return {
      deleteBot,
    };
  },
};
</script>

<style scoped>
th,
td {
  vertical-align: middle;
}
</style>
