<template>
  <dashboard-layout>
    <div class="bots" v-if="bots.length !== 0">
      <items :bots="bots" />
    </div>
    <div v-else-if="pendingBots">
      <div class="d-flex justify-content-center p-4 mt-4">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <div
      v-else
      class="
        w-100
        d-flex
        justify-content-center
        align-items-center
        flex-wrap
        pt-4
      "
    >
      <h3 class="fw-light w-100 text-center pt-5">
        Oluşturulmuş herhangi bir MACD Botunuz bulunmuyor!
      </h3>
      <router-link to="/macd_bots/create" class="btn btn-success mt-3 w-25 py-3"
        ><i class="bi bi-plus-square"></i> Yeni bir Macd botu
        oluştur</router-link
      >
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from "@/layouts/Dashboard.layout.vue";
import Items from "@/components/Dashboard/MacdBot/List/Items";

import { computed } from "@vue/runtime-core";
import { useStore } from "vuex";

export default {
  components: { DashboardLayout, Items },
  setup() {
    const store = useStore();
    store.dispatch("macd_bots/getBots", { url: "/macd_bot/" });
    const bots = computed(() => store.getters["macd_bots/getBots"]);
    const pendingBots = computed(() => store.state.macd_bots.pendingBots);

    return {
      bots,
      pendingBots,
    };
  },
};
</script>

<style></style>
